
























































































































import Vue from 'vue';
import AdminUserService from '@/services/admin/AdminUserService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import AdminChangePassword from '@/components/users/AdminChangePassword.vue';
import DataTable from '@/components/shared/DataTable.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import { MetaInfo } from 'vue-meta';

class AdminUserIndexDto {
  id!: string;
  userName!: string;
  createdAt!: string;
  lastLoginAt!: string;
}

export default Vue.extend({
  components: { FormatedTimestamp, DataTable, AdminChangePassword, DeleteConfirmationModal },
  data() {
    return {
      users: [],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: true },
      currentPage: 1,
      loading: false,
      passwordChangeUserName: '',
      showDeleteModal: false,
      currentIndex: -1,
      showOnlyImportedGuests: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminGuestIndex.meta.title').toString(),
    };
  },

  watch: {
    showOnlyImportedGuests: function () {
      this.loadData();
    },
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },

  methods: {
    openPasswordChangeModal(index: number) {
      const user = this.users[index] as AdminUserIndexDto;
      this.passwordChangeUserName = user.userName;
    },

    closePasswordChangeModal() {
      this.passwordChangeUserName = '';
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminGuestIndex.breadcrumb.last' },
      ]);
    },

    confirmEmail(user: { id: string }) {
      AdminUserService.confirmUserEmail(user.id).then(() => {
        this.loadData();
      });
    },

    loginAs(user: { userName: string }) {
      this.$store.dispatch('AuthStoreModule/loginAs', user.userName).then(() => {
        this.$router.push('/');
      });
    },

    deleteUser() {
      const user = this.users[this.currentIndex] as AdminUserIndexDto;
      AdminUserService.delete(user.id).then(() => {
        this.users.splice(this.currentIndex, 1);
        this.closeDeleteModal();
      });
    },

    loadData() {
      this.loading = true;
      AdminUserService.getCustomerIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue,
        this.showOnlyImportedGuests
      ).then((res) => {
        this.loading = false;
        this.users = res.value.users.items;
        this.pages = res.value.users.totalPages;
      });
    },
    openDeleteModal(index: number) {
      this.currentIndex = index;
      this.showDeleteModal = true;
    },
    closeDeleteModal() {
      this.currentIndex = -1;
      this.showDeleteModal = false;
    },
  },

  created() {
    this.loadData();
    this.setBreadCrumb();
  },
});
